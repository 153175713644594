import React from "react";
const Resume = ({ data }) => {
    if (data) {
        var skillmessage = data.skillmessage;
        var education = data.education.map((education) => {
            return (
                <div className="education" key={education.school}>
                    <div className="educationlogo">
                        <img src={education.image} alt={education.school} />
                    </div>
                    <div className="educationtext">
                        <h3>{education.school}</h3>
                        <h3>{education.location}</h3>
                        <p className="info">
                            {education.degree} <span>&bull;</span>
                            <em className="date">{education.graduated}</em>
                        </p>
                        <p>{education.description}</p>
                    </div>
                </div>
            );
        });

        var volunteer = data.volunteer.map((volunteer) => {
            return (
                <div className="work" key={volunteer.company}>
                    <img
                        className="volunteericon"
                        src={volunteer.image}
                        alt={volunteer.company}
                    />
                    <h3>
                        {volunteer.company}, {volunteer.location}
                    </h3>
                    <p className="info">
                        <u>{volunteer.title}</u>
                        <span>&bull;</span>
                        <em className="date">{volunteer.years}</em>
                        <p>&bull; {volunteer.description}</p>
                    </p>
                </div>
            );
        });

        var work = data.work.map((work) => {
            return (
                <div className="work" key={work.company}>
                    <img src={work.image} alt={work.company} />
                    <h3>
                        {work.company}, {work.location}
                    </h3>
                    <p className="info">
                        <u>{work.title}</u>
                        <span> ({work.type}) </span>
                        <span>&bull; </span>
                        <em className="date">{work.years}</em>
                    </p>

                    <ul>
                        {work.description.map((c) => {
                            return <li key={c.item}>{c.item}</li>;
                        })}
                    </ul>
                </div>
            );
        });
        var skills = data.skills.map(function (skills) {
            return (
                <ul key={skills.name}>
                    <li>{skills.name}</li>
                </ul>
            );
        });
    }

    return (
        <section id="resume">
            <div className="row">
                <div className="three columns header-col">
                    <h1>
                        <span>Education</span>
                    </h1>
                </div>

                <div className="nine columns main-col">
                    <div className="row item">
                        <div className="twelve columns">{education}</div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="three columns header-col">
                    <h1>
                        <span>Volunteer</span>
                    </h1>
                </div>

                <div className="nine columns main-col">
                    <div className="row item">
                        <div className="twelve columns">{volunteer}</div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="three columns header-col">
                    <h1>
                        <span>Work</span>
                    </h1>
                </div>

                <div className="nine columns main-col">{work}</div>
            </div>

            <div className="row skill">
                <div className="three columns header-col">
                    <h1>
                        <span>Key Skills</span>
                    </h1>
                </div>

                <div className="nine columns main-col">
                    <p>{skillmessage}</p>

                    <div className="bars">
                        <ul className="skills">{skills}</ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Resume;
