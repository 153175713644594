import React, { useState } from "react";
import emailjs from "@emailjs/browser";
const Contact = ({ data }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [messageSent, setMessageSent] = useState(false);

    if (data) {
        var contactName = data.name;
        var street = data.address.street;
        var city = data.address.city;
        var state = data.address.state;
        var zip = data.address.zip;
        var phone = data.phone;
        var contactEmail = data.email;
        var contactMessage = data.contactmessage;
    }

    const submitForm = (e) => {
        e.preventDefault();

        emailjs
          .sendForm(
            "service_baysb2w",
            "template_cja20gh",
            "#myform",
            "ArCUTul-8-eFMQKG2"
          )
          .then(
            (result) => {
              setMessageSent(true);
              console.log(result.text);
            },
            (error) => {
              setMessageSent(false);
              console.log(error.text);
            }
          );
    };

    return (
        <section id="contact">
            <div className="row section-head">
                <div className="two columns header-col">
                    <h1>
                        <span>Get In Touch.</span>
                    </h1>
                </div>

                <div className="ten columns">
                    <p className="lead">{contactMessage}</p>
                </div>
            </div>

            <div className="row">
                <div className="eight columns">  
                  {!messageSent  ? (
                    <form id="myform" onSubmit={submitForm}>
                        <fieldset>
                            <div>
                                <label htmlFor="contactName">
                                    Name <span className="required">*</span>
                                </label>
                                <input
                                    type="text"                                    
                                    value={name}
                                    size="35"
                                    id="contactName"
                                    name="from_name"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>

                            <div>
                                <label htmlFor="contactEmail">
                                    Email <span className="required">*</span>
                                </label>
                                <input
                                    type="text"                                    
                                    value={email}
                                    size="35"
                                    id="contactEmail"
                                    name="reply_to"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div>
                                <label htmlFor="contactSubject">Subject</label>
                                <input
                                    type="text"                                    
                                    value={subject}
                                    size="35"
                                    id="contactSubject"
                                    name="subject"
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                            </div>

                            <div>
                                <label htmlFor="contactMessage">
                                    Message <span className="required">*</span>
                                </label>
                                <textarea
                                    cols="50"
                                    rows="15"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    id="contactMessage"
                                    name="message"
                                ></textarea>
                            </div>

                            <div>
                                <button
                                    onClick={submitForm}
                                    type="submit"
                                    className="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </fieldset>
                    </form>
                  ):(
                    <div id="message-success">
                        <i className="fa fa-check"></i>Your message was sent,
                        thank you!
                        <br />
                    </div>
                  )}                
                </div>

                <aside className="four columns footer-widgets">
                    <div className="widget widget_contact">
                        <h4>Address and Phone</h4>
                        <p className="address">
                            {contactName}
                            <br />
                            {contactEmail}
                            <br />
                            <br />
                            {street} <br />
                            {city}, {state} {zip}
                            <br />
                            <span>{phone}</span>
                        </p>
                    </div>
                </aside>
            </div>
        </section>
    );
};

export default Contact;
