import React from "react";

const Footer = ({ data }) => {
  if (data) {
    var networks = data.social.map(function (network) {
      return (
        <li key={network.name}>
          <a href={network.url}>
            <i className={network.className}></i>
          </a>
        </li>
      );
    });
  }

  return (
      <footer className="footer">
          <div className="row">
              <div className="twelve columns">
                  <ul className="social-links">{networks}</ul>

                  <ul className="copyright">
                      <li>
                          Made by{" "} 
                          <a title="David C. Horowitz" href="/">
                              David C Horowitz
                          </a>
                      </li>
                  </ul>
              </div>
              <div id="go-top">
                  <a className="smoothscroll" title="Back to Top" href="#home">
                      <i className="icon-up-open"></i>
                  </a>
              </div>
          </div>
      </footer>
  );
};

export default Footer;
